import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import Icons from "components/icons";
import { Localize } from "components/service";

import './sidebar.scss';


const { SubMenu } = Menu;

const Sidebar = ({ appParams, setToggleSider }) => {

    let {
        selectedKeys = [ 'dashboard' ],
        openKeys = [ 'dashboard' ]
    } = appParams.activeMenu;

    const [ openSubMenu, setOpenSubMenu ] = useState(openKeys);

    useEffect(() => {

        setOpenSubMenu(openKeys)

    }, [ appParams.activeMenu, openKeys ]);

    const items = [
        // {
        //     key     : 'events-place-dates',
        //     label   : <Link to="#"><Icons.Events /><Localize>EVENT_Titles</Localize></Link>,
        //     onClick : () => setToggleSider( true ),
        //     children: [
        //         {
        //             key     : 'events',
        //             label   : <Link to="/events"><Icons.Events /><Localize>EVENT_Titles</Localize></Link>,
        //             onClick : () => setToggleSider( true ),
        //         },
        //         {
        //             key     : 'place-dates',
        //             label   : <Link to="/place-dates"><Icons.PlaceDate /><Localize>PLACE_DATE_Titles</Localize></Link>,
        //             onClick : () => setToggleSider( true ),
        //         }
        //     ]
        // },
        // {
        //     key     : 'halls-schemas',
        //     label   : <Link to="#"><Icons.Halls /><Localize>HALL_Titles</Localize> & <Localize>SCHEMA_Titles</Localize></Link>,
        //     onClick : () => setToggleSider( true ),
        //     children: [
        //         {
        //             key     : 'halls',
        //             label   : <Link to="/halls"><Icons.Halls /><Localize>HALL_Titles</Localize></Link>,
        //             onClick : () => setToggleSider( true ),
        //         },
        //         {
        //             key     : 'schemas',
        //             label   : <Link to="/schemas"><Icons.Schemas /><Localize>SCHEMA_Titles</Localize></Link>,
        //             onClick : () => setToggleSider( true ),
        //         }
        //     ]
        // },
        // {
        //     key     : 'orders',
        //     label   : <Link to="/orders"><Icons.Orders /><Localize>ORDER_Titles</Localize></Link>,
        //     onClick : () => setToggleSider( true ),
        // },
        {
            key     : 'personalData',
            label   : <Link to="/personal-data"><Icons.PersonalData /><Localize>PERSONALDATA_Title</Localize></Link>,
            onClick : () => setToggleSider( true ),
        },
        {
            key     : 'users',
            label   : <Link to="/users"><Icons.Users /><Localize>USER_Titles</Localize></Link>,
            onClick : () => setToggleSider( true ),
        },
        // {
        //     key     : 'languages',
        //     label   : <Link to="/pages"><Icons.Language /><Localize>LANGUAGE_Titles</Localize></Link>,
        //     onClick : () => setToggleSider( true ),
        // },
        // {
        //     key     : 'cities',
        //     label   :  <Link to="/cities/"><Icons.City /><Localize>Cities</Localize></Link>,
        //     onClick : () => setToggleSider( true ),
        // },
        {
            key     : 'languages',
            label   : <Link to="/languages"><Icons.Language /><Localize>LANGUAGE_Titles</Localize></Link>,
            onClick : () => setToggleSider( true ),
        },
        // {
        //     key     : 'general',
        //     label   : <Link to="#"><Icons.General /><Localize>GENERAL_Title</Localize></Link>,
        //     onClick : () => setToggleSider( true ),
        //     children: [
        //         {
        //             key     : 'pages',
        //             label   : <Link to="/pages"><Icons.Pages /><Localize>Label_Pages</Localize></Link>,
        //             onClick : () => setToggleSider( true ),
        //         },
        //         {
        //             key     : 'cities',
        //             label   : <Link to="/cities/"><Icons.City /><Localize>CITY_Titles</Localize></Link>,
        //             onClick : () => setToggleSider( true ),
        //         }
               
        //     ]
        // },
        {
            key     : 'theme-settings',
            label   : <Link to="/theme-settings"><Icons.ThemeSettings /><Localize>Label_Settings</Localize></Link>,
            onClick : () => setToggleSider( true ),
            children: [{
                key     : 'information',
                label   : <Link to="/theme-settings/information">Information</Link>,
                onClick : () => setToggleSider( true ),
            }]
        },
    ];


    return (
        <>
            <Menu
                items        = { items }
                className    = 'sidebar-menu'
                theme        = "dark"
                mode         = "inline"
                selectedKeys = { selectedKeys }
                openKeys     = { openSubMenu }
                onOpenChange = { ( keys ) => setOpenSubMenu( keys ) }
                style        = { { borderRight: 0 } }>
            </Menu>
        </>
    );
};

export default Sidebar;