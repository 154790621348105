import LanguageConst   from "./language-const";
import LanguageActions from "./actions";
import LanguageHelpers from "./helpers";
import LanguageFields  from "./fileds";


const Language = {
    Const   : LanguageConst,
    Actions : LanguageActions,
    Helpers : LanguageHelpers,
    Fields  : LanguageFields
}

export default Language;