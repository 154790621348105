import { LoginPage, ForgotPasswordPage } from "./authorization";
import Dashboard      from "./dashboard/dashboard-page";
import UserPages      from "./user";
import LanguagePages  from "./language";
import Page404        from "./404/404-page";

import "./pages.scss";


export const Pages = {
    Dashboard,
    User      : UserPages,
    Language  : LanguagePages,
    // General  : GeneralPage,
    Page404
   
};

export const GuestPages = {
    LoginPage,
    ForgotPasswordPage,
}