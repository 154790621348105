import React from "react";
import { NavLink } from 'react-router-dom';
import { Skeleton } from 'antd';
import { Pages } from 'components/pages';

import './page-wrap-default.scss';


const PageWrapDefault = ({
    pageHeaderContent  = false,
    pageNavbar         = false,
    loading            = false,
    headerRightBar     = false,
    dataExist          = false,
    title              = '',
    className          = '',
    children }) => {


    if( title === null ){

        title = 'No translate';

    }

    return (
        <div className={ `page ${ className }` }>

            <div className="page-header">
                
                <div className="page-header-top-bar">

                    { !loading ? <h2> { title } </h2> : <h2 className="custom-skeleton" style={{ height: 17 }}/> }

                    { headerRightBar && <div className="right-bar">{ headerRightBar }</div> }

                </div>

                { pageHeaderContent &&

                    <div className="page-header-content">
                        { pageHeaderContent }
                    </div>
                    
                }

            </div>

            { pageNavbar.length &&

                <ul className="page-navigation">
                    
                    { pageNavbar.map( ( { path, label, disabled = false } ) => {

                        return (
                            <li key={ label } title={ disabled === true ? 'Future development' : '' } >

                                <NavLink 
                                    to              = { path }
                                    className       = { disabled === true ? 'disabled' : '' }
                                    activeClassName = "selected"
                                    exact
                                >
                                    { label }
                                </NavLink>
                                
                            </li>
                        );

                    } ) }

                </ul>

            }

            <div className={ `page-content` }>

                { dataExist ? children : <>{ !loading && <Pages.Page404 /> }</> }

                { loading && <Skeleton active={ true } paragraph={ { rows: 15 } } /> }

            </div>

        </div>
    );
}

export default PageWrapDefault;

