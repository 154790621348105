import { Tag  }     from 'antd';
import { Localize } from "components/service";
import UserConst    from "../user-const";
import UserFields   from "../fields";


const usersColumns = [
    {
        title       : <Localize>ID</Localize>,
        dataIndex   : 'id',
        columnIndex : 'ID',
        width       : '5%',
        sorter      : true,
        align       : "center"
    },
    {
        title       : <Localize>USER_Text_FullName</Localize>,
        dataIndex   : 'name',
        columnIndex : 'NAME',
        width       : '15%',
        sorter      : true,
        align       : "center"
    },
    {
        title       : <Localize>email</Localize>,
        dataIndex   : 'email',
        columnIndex : 'EMAIL',
        width       : '15%',
        sorter      : true,
        align       : "center"
    },
    {
        title       : <Localize>GL_Text_Status</Localize>,
        dataIndex   : 'status',
        columnIndex : 'STATUS',
        width       : '15%',
        sorter      : true,
        align       : "center"
    },
    {
        title       : <Localize>lang</Localize>,
        dataIndex   : 'lang',
        columnIndex : 'LANG',
        width       : '5%',
        sorter      : true,
        align       : "center"
    },
    {
        title       : <Localize>USER_Text_LastVisit</Localize>,
        dataIndex   : 'last_visit_at',
        columnIndex : 'LAST_VISIT_AT',
        width       : '15%',
        sorter      : true,
        align       : "center"
    },
    {
        title       : <Localize>GL_Text_Action</Localize>,
        dataIndex   : 'action',
        columnIndex : 'ACTION',
        align       : 'center',
        width       : '10%'
    }
];

const usersData = ( users ) => {

    if ( Object.keys( users ).length === 0 ) {

        return [];

    }

    return users.map( user => {

        return {
            key           : user.id,
            id            : user.id,
            name          : <>
                <UserFields.TitleLink modelPath={ `${ UserConst.basePath }/${ user.id }` } text={ <>{ user.name } { user.surname } </> } />
                { user.is_admin === true ? <Tag color="volcano" style={ { marginLeft: "5px" } } >admin</Tag> : '' }
            </>,
            email         : user.email,
            lang          : user.lang,
            status        : user.status,
            last_visit_at : <>{ user.last_visit_at ? <>{ user.last_visit_at } <br/> { user.last_visit_ago } </> : <>no visit <br/> empty </> }</>,
            action        : <UserFields.ActionList user={ user } />
        };

    });
};


const UserTableMainHelper = {
    columns : usersColumns,
    data    : usersData,
}

export default UserTableMainHelper;