import { InMemoryCache, makeVar } from "@apollo/client";

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                guestParams: {
                    read () {
                        return guestParamsVar();
                    }
                },
                appParams: {
                    read () {
                        return appParamsVar();
                    }
                }
            },
        },
    },
});

export const guestParamsInitial = {
    mainBlockClass : [],
    appBlockClass  : [],
};

export const guestParamsVar = makeVar(guestParamsInitial);

export const appParamsInitial = {
    headerClass    : [],
    mainBlockClass : [],
    appBlockClass  : [],
    activeMenu     : {
        selectedKeys : [],
        openKeys     : []
    },
    rememberQueryParams : {
        halls  : false,
        cities : false
    },
    profileComplete : false,
    moment    : () => {},
    languages : {},
    me        : {}
};

export const appParamsVar = makeVar(appParamsInitial);

export const linkStateVar = makeVar(false);

export const breadCrumbsVar = makeVar([]);

export default cache;