import React, { useState } from 'react';
import { Link }     from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { Layout }   from 'antd';
import { MenuFoldOutlined } from '@ant-design/icons';
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/he";

import { GET_APP_PARAMS } from "graphql/query/local-store-gql";

import Menus                  from 'components/menus';
import { TopBar }             from 'components/layout';
import { AppRoutes }          from "components/routes";
import { Suspense }           from "components/request-result";
import { DateTime, Localize } from 'components/service';
import { useInitQuery, useLogout, useWindowSize, useVarParam } from "components/use-hooks";
import Icons from 'components/icons';
import logo  from "assets/download-4.png";

import './app.scss';


const localeMoment = moment;
const { Sider }    = Layout;

const App = () => {

    const [ toggleSider, setToggleSider ] = useState(false);

    const { loading, error, me, languages, languageDefault } = useInitQuery( "cache-and-network", true ),
        { data: { appParams } } = useQuery( GET_APP_PARAMS ),
        { logout } = useLogout();

    localeMoment.locale( me ? me.lang : "en" );
    
    useVarParam().set({
        "moment"          : localeMoment,
        "me"              : me,
        "languages"       : languages,
        "languageDefault" : languageDefault,
    });
    

    return (
        <div className={ `app ${ appParams.appBlockClass.join(" ") }` }>

            <Suspense 
                type       = "block" 
                state      = { { loading, error } } 
                blockClass = "loader-main"
            >

                <Layout className='app-layout'>

                    <Sider
                        trigger        = { null }
                        collapsed      = { useWindowSize().width < 1200 && toggleSider }
                        className      = "sider"
                        width          = "200"
                        breakpoint     = "xl"
                        collapsedWidth = "0"
                        onBreakpoint   = { (broken) => setToggleSider(broken) }
                    >
                        <span 
                            className = 'nav-opener absolute' 
                            onClick   = { () => setToggleSider(!toggleSider) } 
                        >

                            <MenuFoldOutlined />

                        </span>

                        <div className="holder-date-logo">

                            <Link className="logo" to="/">
                                
                                {/* <div className="logo-box">
                                    <img src={ logo } alt="Family Tree - ADM" />
                                </div> */}
                                
                                <div className="logo-text">
                                    Family Tree - ADM
                                </div>

                            </Link>

                        </div>

                        <DateTime />

                        <Menus.Sidebar 
                            appParams      = { appParams } 
                            setToggleSider = { setToggleSider } 
                        />

                        <span 
                            className = 'sider-logout' 
                            onClick   = { () => logout({ redirectRoute: "/" }) }
                        >
                            <span>

                                <Icons.LogOut className="logout-icon" /> 
                                <Localize>GL_Text_Logout</Localize>
                            
                            </span>
                        </span>

                    </Sider>

                    <Layout className="content-holder">
                        <main id='main'>

                            <TopBar toggleClick = { () => setToggleSider( !toggleSider ) } />

                            <AppRoutes />

                        </main>
                    </Layout>
                </Layout>
            </Suspense>
        </div>
    );
};

export default App;