import React from "react";
import { Skeleton }   from 'antd';

import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault }   from "components/layout";
import { Localize }          from "components/service";
import User                  from "components/users";


const basePath = User.Const.basePath;

const UserPageCreate = () => {

    const breadcrumbs = [
        {
            label: Localize({ children: "USER_Titles" }).props.children,
            path: `${ basePath }`
        },
        {
            label: Localize({ children: "GL_Text_Create" }).props.children,
            path: `${ basePath }/create`
        }
    ]

    useBreadCrumbs( breadcrumbs );
    useActiveMenuItem( [ "users" ], [ "users" ] );

    const pageNavbar = [
        {
            label : Localize( { children: "GL_Text_Overview" } ).props.children,
            path  : `${ basePath }/create`
        }
    ];

    return (

        <PageWrapDefault
            className  = "page-user create single"
            title      = { <Localize>GL_Text_Create</Localize> }
            dataExist  = { true }
            pageNavbar = { pageNavbar }
            staticPath = { `${ basePath }/create` }
        >
            
            <div className="row-grid col-lg-2">
                <div className="col-left">
                
                    <User.Forms.Create />
                    
                </div>
            </div>

        </PageWrapDefault>
    );
};

export default UserPageCreate;