import { Link } from "react-router-dom";
import { DownOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { useLogout, useVarParam } from "components/use-hooks";
import Icons from 'components/icons';
import { Localize } from "components/service";

import './dropdown-menu.scss';


const DropMenu = () => {

    const me = useVarParam().get( 'me' );
    const { logout } = useLogout();

    const items = [
        {
            key: '1',
            label: <Link to={ { pathname: `/users/${ me?.id }` } } > <UserOutlined /> &nbsp; <Localize>GL_Text_MyProfile</Localize></Link>,
        },
        {
            key: '2',
            label: <span onClick={ () => logout({ redirectRoute: "/" }) }><LogoutOutlined /> &nbsp; <Localize>GL_Text_Logout</Localize></span>
        }
    ];

    return (

        <Dropdown
            className        = "dropdown-profile"
            overlayClassName = "dropdown-profile-overlay"
            menu             = { { items } }
            trigger          = { [ 'click' ] }
        >

            <span className="my-profile-link" onClick={ e => e.preventDefault() }>
                <Icons.Profile className="profile-icon" /> <DownOutlined />
            </span>

        </Dropdown>
    )
};


export default DropMenu;