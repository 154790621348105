import React       from "react";
import { Link }    from "react-router-dom";


const FieldLinkText = ( { modelPath, text = '', className = ''  } ) => {

    return (
        <Link
            className = { `link-text ${ text ? className : '' }` }
            to        = { `${ modelPath }` }
        >
            { text ? text : '' }
        </Link>
    );

};

export default FieldLinkText;