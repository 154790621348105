import React from "react";
import { Form, Input, Button, Checkbox } from 'antd';
import { useMutation } from "@apollo/client";

import { USER_UPDATE } from "graphql/mutation/user-gql";

import { errorNotification, successNotification } from "components/request-result";
import { Localize } from "components/service";
import UserFormItems       from "../fields";
import LanguageSelectField from "components/language/fileds/language-select-field";
import UserFormDelete      from "components/users/actions/hall-delete-modal";


const UserFormEdit = ( { user } ) => {

    const [ form ] = Form.useForm();

    const [ userUpdate, { loading } ] = useMutation( USER_UPDATE,
        {
            update( cache, { data }) {

                const {
                    userUpdate: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title       : label,
                    description : message
                });

            },
            onError( error ) {
                errorNotification( error );
            }
        }
    );

    return (
        <>
            <Form
                key       = "edit-user"
                layout    = "vertical"
                className = "user-form"
                initialValues = { {
                    name     : user.name,
                    surname  : user.surname,
                    email    : user.email,
                    phone    : user.phone,
                    status   : user.status,
                    lang     : user.lang,
                    is_admin : user.is_admin,
                } }
                form      = { form }
                onFinish = { ( values ) => {
                    userUpdate({
                        variables : {
                            input : { 
                                id : user.id,
                                ...values 
                            }
                        }
                    }).catch( ( error ) => {
                        errorNotification( error );
                    });
                } }
            >

                <Form.Item
                    name  = "name"
                    label = { <Localize>USER_Field_Name</Localize> }
                    rules = { [
                        { required: true, message: <Localize>GL_RULE_FieldRequired</Localize> }
                    ] }
                >
                    <Input name = "name"/>
                </Form.Item>

                <Form.Item
                    name  = "surname"
                    label = { <Localize>USER_Field_Surname</Localize> }
                    rules = { [
                        { message: <Localize>GL_RULE_FieldRequired</Localize> }
                    ] }
                >
                    <Input name="surname" />
                </Form.Item>

                <Form.Item
                    name  = "email"
                    label = { <Localize>email</Localize> }
                    rules = { [
                        { required: true, message: <Localize>GL_RULE_FieldRequired</Localize> }
                    ] }
                >
                    <Input name="email" />
                </Form.Item>

                <Form.Item
                    name  = "phone"
                    label = { <Localize>GL_Text_Phone</Localize> }
                    rules = { [
                        { message: <Localize>GL_RULE_FieldRequired</Localize> }
                    ] }
                >
                    <Input name="phone" />
                </Form.Item>

                <Form.Item
                    name  = "status"
                    label = { <Localize>GL_Text_Status</Localize> }
                >
                    <UserFormItems.StatusSelect />
                </Form.Item>

                <Form.Item
                    name  = "lang"
                    label = { <Localize>LANGUAGE_Title</Localize> }
                >
                    <LanguageSelectField />
                </Form.Item>

                <Form.Item
                    name          = "is_admin"
                    label         = { <Localize>Admin</Localize> }
                    valuePropName = "checked"
                >
                    <Checkbox><Localize>GL_Text_Yes</Localize>?</Checkbox>
                </Form.Item>
                
                <Form.Item
                    name  = "password"
                    label = { <Localize>Password</Localize> }
                    rules={[
                        { min: 8, message: 'Password must be at least 8 characters' },
                        { required: false, message: 'Please input password' }
                    ]}
                >
                    <Input type="password" name="password" />
                </Form.Item>

                <div className="form-btn-holder">

                    <UserFormDelete user={ user } />

                    <Button
                        className = "btn-right"
                        loading   = { loading }
                        disabled  = { loading }
                        type      = "primary"
                        htmlType  = "submit"
                    >
                        <Localize>GL_Text_SaveChanges</Localize>
                    </Button>

                </div>

            </Form>
        </>
    );

};

export default UserFormEdit;