import React from "react";

const FieldCreateUpdate = ( { model } ) => {

    return(
        <div className="d-grid text-right mt-20">
            <small>created : { model.created_at }</small>
            <small>updated : { model.updated_at }</small>
            { model.last_visit_at ? <small>last_visit : { model.last_visit_at }</small> : '' }
        </div>
    );

};

export default FieldCreateUpdate;
