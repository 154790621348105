import { Localize }  from "components/service";

const groupBasePath = '/users';

const userStatus = [
    { text: <Localize>GL_Text_Active</Localize>, value: 'active' },
    { text: <Localize>GL_Text_Block</Localize>, value: 'block' }
];

const UserConst = {
    basePath : groupBasePath,
    status   : userStatus 
};

export default UserConst;