import React from 'react';
import { Select } from 'antd';

import Icons        from 'components/icons';
import UserConst    from 'components/users/user-const';
import { Localize } from "components/service";

const { Option } = Select;

const UserFormFieldItemStatus = ( props ) => {

    return (
        <Select
            { ...props }
            suffixIcon  = { <Icons.Arrow /> }
            placeholder = { Localize({ children: "FORMS.Select_Placeholder_ChooseStatus" }).props.children }
        >
            { UserConst.status.map( ( item ) => (

                <Option key={ item.text } value={ item.value }>

                    <span className="color-box" style={ { background: item.value } }></span>

                    <span >
                        { item.text }
                    </span>

                </Option>

            )) }

        </Select>
    );

}

export default UserFormFieldItemStatus;