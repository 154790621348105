import React from "react";


const FieldEmpty = () => {

    return(
        <small className="field-empty">empty</small>
    );

};

export default FieldEmpty;
