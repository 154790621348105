import { useEffect } from "react";
import { breadCrumbsVar } from "graphql/cache";


const useBreadCrumbs = ( breadcrumbs ) => {

    /*eslint-disable */
    useEffect( () => {
        breadCrumbsVar( breadcrumbs )
        return () => breadCrumbsVar( [] )
    }, [breadcrumbs] );
    /*eslint-enable */
};

export default useBreadCrumbs;