import React from "react";
import { Link, NavLink } from 'react-router-dom';
import { Space, Tooltip } from 'antd';

import { useVarParam } from "components/use-hooks";


const LanguageSwitchLink = ( { modelPath, model, addPath = '', className = 'justify-content-center' } ) => {

    const languages = useVarParam().get( 'languages' );

    return (
        <>
            <ul className={ `language-switch-link ${ className }` }>

                { languages.map( ( { slug } ) => {

                    const relModel = model.relRelatives.find( ( { lang } ) => lang === slug );

                    if( relModel ){

                        return (
                            <Tooltip 
                                title = { relModel.title ?? relModel.id }
                                key   = { relModel.id }
                            >
                                <li key={ slug }>

                                    <NavLink
                                        key             = { relModel.id }
                                        className       = { `ant-btn ${ relModel.id === model.id ? "selected" : "translate" }`}
                                        exact
                                        to              = {{
                                            pathname: `${ modelPath }/${ relModel.group_id }/${ relModel.lang }${ addPath }`
                                        }}
                                    >
                                        { slug }
                                    </NavLink>
                                </li>

                            </Tooltip>
                        );

                    } else {

                        return (
                            <li key={ slug }>
                                <NavLink 
                                    key       = { slug }
                                    className = "ant-btn"
                                    to        = {{
                                        // pathname: `${ modelPath }/create/${ model.group_id }/${ slug }`
                                        pathname: `${ modelPath }/create`
                                    }}
                                >
                                    { slug }
                                </NavLink>
                            </li>
                        );

                    }

                } ) }
                
            </ul>

            {/* <Space wrap className={ `language-switch-link ${ className }` }>

                { languages.map( ( { slug } ) => {

                    const relModel = model.relRelatives.find( ( { lang } ) => lang === slug );

                    if( relModel ){

                        return (
                            <Tooltip 
                                title = { relModel.title ?? relModel.id }
                                key   = { relModel.id }
                            >
                                <Link
                                    key       = { relModel.id }
                                    className = { `ant-btn ${ relModel.id === model.id ? "active" : "translate" }`}
                                    to        = {{
                                        pathname: `${ modelPath }/${ relModel.id }${ addPath }`
                                    }}
                                >
                                    { slug }
                                </Link>
                            </Tooltip>
                        );

                    } else {

                        return (
                            <Link 
                                key       = { slug }
                                className = "ant-btn"
                                to        = {{
                                    pathname: `${ modelPath }/create/${ model.group_id }/${ slug }`
                                }}
                            >
                                { slug }
                            </Link>
                        );

                    }

                } ) }

            </Space> */}
        </>
    )

}

export default LanguageSwitchLink;