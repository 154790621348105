import UsersPage      from "./users-page";
import UserPageCreate from "./user-create-page";
import UserPageSingle from "./user-single-page";


const UserPages = {
    Archive : UsersPage,
    Create  : UserPageCreate,
    Single  : UserPageSingle
}

export default UserPages;