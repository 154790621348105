import UserConst   from "./user-const";
import UserHelpers from "./helpers";
import UserFields  from "./fields";
import UserForms   from "./forms";


const User = {
    Const   : UserConst,
    Helpers : UserHelpers,
    Fields  : UserFields,
    Forms   : UserForms
}

export default User;