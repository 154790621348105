import React from "react";

import { useQuery }   from "@apollo/client";
import { GET_USER }   from "graphql/query/user-gql";

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault }   from "components/layout";
import { Localize }          from "components/service";
import UserRoute             from "components/routes/user/user-route";
import UserConst             from "components/users/user-const";


const basePath = UserConst.basePath;

const UserPageSingle = ( { match } ) => {

    useActiveMenuItem( ["users"], ["users"] );

    console.log(match);

    const pageID = match ? parseInt( match.params.userID ) : null;

    const { data: { user } = {}, loading } = useQuery( GET_USER, {
        variables: {
            id: pageID
        },
        fetchPolicy     : 'cache-and-network',
        nextFetchPolicy : 'cache-first'
    });

    const pageNavbar = [
        {
            label : Localize( { children: "GL_Text_Overview" } ).props.children,
            path  : !loading && user ? `${ basePath }/${ user.id }` : `${ basePath }`
        },
        // {
        //     label : Localize({ children: "USER_Text_Editor" }).props.children,
        //     path  : !loading && user ? `${ basePath }/${ user.id }/view` : `${ basePath }`
        // }
    ];

    return (
        <PageWrapDefault
            className  = "page-user"
            loading    = { loading }
            title      = { !loading && user ? <>{ user.name } { user.surname }</> : "Page 404" }
            dataExist  = { !loading && user }
            // pageNavbar = { user ? pageNavbar : false }
            staticPath = { `${ basePath }/${ pageID }` }
        >
            
            <UserRoute user={ user } />

        </PageWrapDefault>
    );
};

export default UserPageSingle;