import { Button, Upload } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

import { errorNotification } from "components/request-result";

const { Dragger } = Upload;


const UploadFile = ({
    loading,
    uploadMutation  = () => { },
    accept          = '.xml',
    extraClass      = '',
    variables       = {},
    showUploadList  = false,
    defaultFileList = [],
    uploadBtn       = <Button style={ { maxWidth: '100%' } } type='primary'>
        <PlusOutlined /> Import entity</Button>,
}) => {

    const props = {
        className: `file-upload ${ extraClass }`,
        disabled: loading,
        accept,
        defaultFileList,
        showUploadList,
        beforeUpload: (file) => {

            let uploadVar;

            if (variables.input !== undefined) {

                uploadVar = { ...variables };
                uploadVar.input.file = file;

            } else {

                uploadVar = { ...variables, file }

            }

            uploadMutation({

                variables: {
                    ...uploadVar
                }

            }).catch((error) => {

                errorNotification(error);

            });

            return false;
        }
    };


    return (
        <Dragger { ...props }>
            { uploadBtn }
        </Dragger>
    );
}

export default UploadFile;


