import React from "react";

import { useTranslation } from "components/use-hooks";


const Localize = ( { children, wrap = false, wrapClass = "", ...params } ) => {

    const translations = useTranslation();

    let translation = translations[children] ? translations[children] : children;

    if( Object.keys(params).length ){

        const regExp = new RegExp(/\{ [A-Za-z]+ \}+/, "g");

        let variableKeys, strings = [];

        while ( (variableKeys = regExp.exec(translation)) ) {
            strings.push(
                variableKeys[0].replace(/[\W_]+/g, "")
            );
        }

        return translation.split(regExp).reduce( (translated, curVal, index) => {
            translated.push(curVal)

            if(strings[index]){

                if( typeof params[strings[index]] === "object" ){
                    translated.push(
                        React.cloneElement(
                            params[strings[index]], { key: index }
                        )
                    )
                } else {
                    translated.push(params[strings[index]]);
                }
            }

            return translated;
        }, []);

    }

    if(wrap){
        let CustomTag = wrap === true ? `span` : `${ wrap }`;

        return <CustomTag className={ wrapClass }>{ translation }</CustomTag>;
    }

    return <>{ translation }</>;
};

export const localizeIt = ( key, params ) => {
    return Localize({children: key, ...params }).props.children;
}

export default Localize;