import React, { useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";

import { GET_LANGUAGES } from "graphql/query/language-qql";

import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import Language from "components/language";
import { Localize } from "components/service";


const basePath    = Language.Const.basePath;
const tableHelper = Language.Helpers.TableMain;

const LanguagesPage = () => {

    useActiveMenuItem( [ "languages" ], [ "languages" ] );

    const breadcrumbs = [
        {
            label : Localize({ children: "LANGUAGE_Titles" }).props.children,
            path  : `${ basePath }`
        }
    ]

    useBreadCrumbs( breadcrumbs );

    let match = useRouteMatch( `${ basePath }/page/:pageNum` );

    const [ searchText, setSearchText ] = useState( "" );
    const [ filters, setFilters ]       = useState( {} );

    return (
        <PageWrapDefault
            className  = "page-languages"
            dataExist  = { true }
            title      = { <Localize>LANGUAGE_Titles</Localize> }
        >
            <Tables.Main
                modelName    = "languages"
                query        = { GET_LANGUAGES }
                varSet       = { { perPage: 30 } }
                routeUrl     = { `${ basePath }` }
                currentPage  = { match ? parseInt( match.params.pageNum ) : 1 }
                searchText   = { searchText }
                objFilter    = { filters }
                tableHelper  = { tableHelper }
                // totalCounter = { true }
            >
                <div className="table-action-bar">

                    <div className="col">

                        <Link
                            className = "ant-btn ant-btn-primary"
                            type      = "primary"
                            to        = { `${ basePath }/create` }
                        >
                            <Localize>GL_Text_AddNew</Localize>
                        </Link>

                    </div>

                </div>

            </Tables.Main>

        </PageWrapDefault>
    )
};

export default LanguagesPage;