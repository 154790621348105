import React from "react";
import { Select }   from "antd";

import { useVarParam } from "components/use-hooks";


const { Option } = Select;

const LanguageSelectField = ( { ...props } ) => {

    const languages = useVarParam().get( 'languages' );

    const selectSearchField = (

        languages.map( ( { id, title, slug } ) => {
            return (
                <Option key={ id } value={ slug }>
                    { title }
                </Option>
            )
        })

    );
  
    return (
        <Select
            onSelect     = { props.onSelect }
            // showSearch
            placeholder  = "Select a languages"
            { ...props }
            filterOption = { false }
        >
            { selectSearchField }
        </Select>
    );
}

export default LanguageSelectField;