// import HallFormEdit       from "./edit-form";
// import HallFormFilterMain from "./filter-main-form";
import UserFormCreate from "./create-form";
import UserFormEdit   from "./edit-form";

const UserForms = {
    Create     : UserFormCreate,
    Edit       : UserFormEdit
    // FilterMain : HallFormFilterMain
};

export default UserForms;