import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Table, Empty, Tag } from 'antd';
import { useQuery } from "@apollo/client";
import SkeletonCustom from "components/skeleton";
import { Localize } from "components/service";
import { whereConditionBuilder } from "utils";

const SkeletonData = SkeletonCustom.TableData;


const TableMain = ({
    querySkip    = false,
    modelName,
    query,
    currentObj,
    routeUrl,
    currentPage,
    searchText,
    objFilter,
    tableHelper,
    children,
    varSet,
    extraClass   = "",
    tableVisible = true,
    sortingName  = false,
    resetSorting = false,
    resetPaginationPage = false,
    setVariables = () => { },
    expandable   = {},
    rowSelection = false,
    objOrderBy   = [],
    // objOrderBy   = [{ column: "ID", order: "DESC" }],
    tableLayout  = 'fixed',
    totalCounter = false,
    marginY      = "my-6"
}) => {

    const history = useHistory();
    const perPage = varSet?.perPage ?? 10;
    const [ orderBy, setOrderBy ] = useState( objOrderBy );

    const variables = {
        text  : searchText,
        first : perPage,
        page  : currentPage,
        where : whereConditionBuilder( objFilter?.where ?? objFilter ),
        orderBy,
        ...varSet ?? undefined
    };

    let { data = {}, loading } = useQuery( query, {
        skip            : querySkip,
        variables       : { ...variables },
        fetchPolicy     : 'cache-and-network',
        // nextFetchPolicy : 'cache-first'
    });

    const model = data[modelName] ?? {};
    const { paginatorInfo = {} } = model;
    
    /*eslint-disable */
    useEffect( () => {
        setVariables( variables );
    }, [ data ] );

    useEffect(() => {
        if (resetSorting) setOrderBy(resetSorting);
    }, [ resetSorting ]);
    /*eslint-enable */
    
    useEffect(() => {

        if ( searchText !== '' && searchText !== undefined ){

            history.push( routeUrl )

        }

        if (resetPaginationPage) {
            history.replace( routeUrl );
        }

    }, [searchText, history, routeUrl, resetPaginationPage]);

    const dataSource = loading ?
        SkeletonData( tableHelper.columns, perPage, marginY ) :
        tableHelper.data( model.data || model, currentObj, variables );

    return (
        <>
            { children }

            { tableVisible &&
                <>

                    { !loading && totalCounter && 
                    
                        <div className="row mb-10">

                            <div className="col d-flex justify-content-end">

                                <Tag color="default"><Localize>GL_Text_Total</Localize> : { paginatorInfo.total ?? 0 } </Tag>
                                <Tag color="default">items / page : { perPage }</Tag>
                                    
                            </div>

                        </div>

                    }

                    { loading && totalCounter && 
                    
                        <div className="row mb-10">

                            <div className="col d-flex justify-content-end">

                                <Tag color="default"><Localize>GL_Text_Total</Localize> : 000 </Tag>
                                <Tag color="default">items / page : { perPage }</Tag>
                                    
                            </div>

                        </div>

                    }
                    <Table
                        className    = { `table-main ${ extraClass }` }
                        rowSelection = { rowSelection }
                        locale       = { {
                            emptyText : (
                                <div className="no-data-box">
                                    <Empty image={ Empty.PRESENTED_IMAGE_SIMPLE } />
                                    <span className="no-data-text"><Localize>GL_Text_NoDat</Localize></span>
                                </div>
                            ),
                            triggerDesc : <Localize>TABLES.Column_Sorting_Tooltip_Descending</Localize>,
                            triggerAsc  : <Localize>TABLES.Column_Sorting_Tooltip_Ascending</Localize>,
                            cancelSort  : <Localize>TABLES.Column_Sorting_Tooltip_Cancel</Localize>
                        } }
                        expandable  = { expandable }
                        dataSource  = { dataSource }
                        columns     = { tableHelper.columns }
                        tableLayout = { tableLayout }
                        size        = "middle"
                        pagination  = { {
                            // pageSizeOptions: [ "10", "20" ],
                            showSizeChanger : false,
                            position : [ "bottomCenter" ],
                            pageSize : perPage,
                            total    : paginatorInfo?.total,
                            current  : currentPage,
                            hideOnSinglePage : !( paginatorInfo?.total / paginatorInfo?.perPage > 1 ),
                            onChange: ( page ) => {

                                let path = page === 1 ? `${ routeUrl }` : `${ routeUrl }/page/${ page }`
                                history.push( path );

                            }

                        } }
                        onChange = { ( pagination, filters, { column, order } ) => {

                            if ( column && order ){

                                const orderBy = [{ 
                                    column : column.columnIndex, 
                                    order  : order === "ascend" ? "ASC" : "DESC" 
                                }];

                                setOrderBy( orderBy )
                                sortingName && localStorage.setItem( sortingName, JSON.stringify(orderBy ) );

                            }

                        } }
                    />
                
                </>
            }

        </>
    );
};

export default TableMain;