import React, { useState } from 'react';

import Menus                  from 'components/menus';
import Breadcrumbs            from 'components/breadcrumbs';
import { MenuUnfoldOutlined } from '@ant-design/icons';

import './top-bar.scss';


const TopBar = ({ toggleClick }) => {
    return (
        <div className="top-bar">
            <span 
                className = 'nav-opener' 
                onClick   = { () => toggleClick() } >
                
                <MenuUnfoldOutlined />
            
            </span>

            <Breadcrumbs />
            <Menus.DropMenu />
        </div>
    );
}

export default TopBar;