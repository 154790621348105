import LanguageConst  from "../language-const";
import LanguageFields from "../fileds";
import { Localize }   from "components/service";
import { StarOutlined, StarFilled, StarTwoTone } from '@ant-design/icons';


const languagesColumns = [
    {
        title       : <Localize>ID</Localize>,
        dataIndex   : 'id',
        columnIndex : 'ID',
        width       : '5%',
        sorter      : true,
        align       : "center"
    },
    {
        title       : <Localize>GL_Text_Title</Localize>,
        dataIndex   : 'title',
        columnIndex : 'TITLE',
        width       : '30%',
        sorter      : true
    },
    {
        title       : <Localize>GL_Text_Slug</Localize>,
        dataIndex   : 'slug',
        columnIndex : 'SLUG',
        sorter      : true,
        width       : '10%',
        align       : "center"
    },
    {
        title       : <Localize>GL_Text_Status</Localize>,
        dataIndex   : 'status',
        columnIndex : 'STATUS',
        sorter      : true,
        width       : '10%',
        align       : "center"
    },
    {
        title       : <Localize>GL_Text_Default</Localize>,
        dataIndex   : 'default',
        columnIndex : 'DEFAULT',
        sorter      : true,
        width       : '10%',
        align       : "center"
    },
    {
        title       : <Localize>GL_Text_Action</Localize>,
        dataIndex   : 'action',
        columnIndex : 'ACTION',
        align       : 'center',
        width       : '10%'
    }
];


const languagesData = ( languages ) => {

    if ( !languages ){
        return [];
    }

    return languages.map( language => {

        return {
            key       : language.id,
            id        : language.id,
            title     : <LanguageFields.TitleLink language={ language } />,
            slug      : language.slug,
            status    : language.status  ? 'active' : 'not active',
            default   : language.default ? <StarFilled style={{ fontSize: '16px', color: 'orange' }} /> : <StarOutlined />,
            action    : <></>
        };

    });
};


const LanguagesTableHelper = {
    columns : languagesColumns,
    data    : languagesData,
}

export default LanguagesTableHelper;