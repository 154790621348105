import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import UserPageSub from "components/pages/user/sub-pages";

const UserRoute = ({ user }) => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }` } exact>
                <UserPageSub.OverView user={ user } />
            </Route>
        </Switch>
    );
};

export default UserRoute;
