import FieldCreateUpdate from "./create-update-at-field";
import FieldEmpty        from "./empty-field";
import FieldIdGroupId    from "./id-group-id-field";
import FieldInfoTextSync from "./info-text-sync-field";
import FieldLinkText     from "./link-text-field";
import FieldNoTranslate  from "./no-translate-field";


const Fields = {
    CreateUpdate : FieldCreateUpdate,
    Empty        : FieldEmpty,
    IdGroupId    : FieldIdGroupId,
    InfoTextSync : FieldInfoTextSync,
    LinkText     : FieldLinkText,
    NoTranslate  : FieldNoTranslate,
}

export default Fields;