import { Breadcrumb } from "antd";
import { Link } from 'react-router-dom';
import { breadCrumbsVar } from "graphql/cache";
import { useReactiveVar } from "@apollo/client";
import { HomeOutlined, RightOutlined } from '@ant-design/icons';

import './breadcrumbs.scss';


const Breadcrumbs = () => {

    const breadcrumbs = useReactiveVar( breadCrumbsVar );

    return (
        <Breadcrumb
            separator = { <RightOutlined style={ { fontSize: '11px' } } /> }
            className = "main-breadcrumb">
            
            { breadcrumbs.length ? 
                <>
                    <Breadcrumb.Item key="home"><Link to="/"><HomeOutlined style={ { fontSize: '16px' } } /></Link></Breadcrumb.Item>
                    { breadcrumbs.map(item => <Breadcrumb.Item key={ item.path }> <Link to={ item.path }>{ item.label }</Link></Breadcrumb.Item>) }
                </> : 
                <>
                    <Breadcrumb.Item key="home"><Link to="/"><HomeOutlined style={ { fontSize: '16px' } } /></Link></Breadcrumb.Item>
                </>
            }

            {/* { 
                breadcrumbs.length &&
                <>
                    <Breadcrumb.Item key="home"><Link to="/"><HomeOutlined style={ { fontSize: '16px' } } /></Link></Breadcrumb.Item>
                    { breadcrumbs.map(item => <Breadcrumb.Item key={ item.path }> <Link to={ item.path }>{ item.label }</Link></Breadcrumb.Item>) }
                </>
            } */}

        </Breadcrumb>
    )
};

export default Breadcrumbs;
