import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { Form, Input, Button } from 'antd';
import { USER_LOGIN } from "graphql/mutation/user-gql";
import { IS_LOGGED_IN } from "graphql/query/local-store-gql";
import { useAuthRedirect } from "components/use-hooks";
import { errorNotification } from 'components/request-result';
import { Localize } from "components/service";

import logo from "assets/download-4.png";


const SignInForm = () => {

    const [ form ] = Form.useForm();
    const authRedirect = useAuthRedirect();

    const [ loginRequest, { loading } ] = useMutation(
        USER_LOGIN,
        {
            update(cache, { data: { userLogin } }) {

                const { access_token } = userLogin;

                localStorage.setItem("authToken", access_token);
                cache.writeQuery({
                    query: IS_LOGGED_IN,
                    data: {
                        isLoggedIn: access_token,
                    },
                });

                authRedirect();
            },
            onError(error) {
                errorNotification(error);
            }
        }
    );

    return (
        <>

        <div className="sign-in-form">

            <div className="holder-date-logo">
                <Link className="logo" to="/">
                    <div className="logo">
                        <img src={ logo } alt="ftreebook.com" style={ { height: "39px" } }/>
                    </div>
                    <div className="logo-text">
                        FtreeBook<small className="logo-text-sub">.com</small> - ADM
                    </div>
                </Link>
            </div>

            <Form
                className=""
                form={ form }
                layout="vertical"
                onFinish={ (values) => {
                    loginRequest({
                        variables: {
                            data: {
                                username: values.username,
                                password: values.password,
                            }
                        },
                    }).catch((error) => {
                        errorNotification(error);
                    });
                } }
            >
                <Form.Item
                    label={ <Localize>GL_Text_Login</Localize> }
                    name="username"
                >
                    <Input
                        name="username"
                        placeholder={ Localize({ children: "Email" }).props.children }
                    />
                </Form.Item>
                <Form.Item
                    label={ <Localize>GL_Text_Password</Localize> }
                    name="password"
                    rules={ [
                        {
                            required: true,
                            message: <Localize>GL_RULE_FieldRequired</Localize>
                        }
                    ] }
                >
                    <Input.Password
                        name="password"
                        placeholder={ Localize({ children: "GL_Text_Password" }).props.children }
                    />
                </Form.Item>
                <Form.Item>
                    <div className="form-actions">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={ loading }
                        >
                            <Localize>GL_Text_SingIn</Localize>
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>

        </>
    );
};


export default SignInForm;