import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages } from "components/pages";


export default function LanguagesRoute() {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }` } exact>
                <Pages.Language.Archive />
            </Route>
            {/* <Route path={ `${ path }/page/:pageNum` } exact>
                <Pages.City.Archive />
            </Route>
            <Route path={ `${ path }/page` } exact>
                <Pages.City.Archive />
            </Route>
            <Route path={ `${ path }/create` } exact>
                <Pages.City.Create />
            </Route>
            <Route path={ `${ path }/:cityID/create/:lang` } exact>
                { ({ match, history }) => <Pages.City.Create match={ match } history={ history } /> }
            </Route>
            <Route path={ `${ path }/:cityID` } >
                { ({ match }) => <Pages.City.Single match={ match } exact /> }
            </Route> */}
        </Switch>
    );

}
