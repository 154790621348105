import { useQuery } from "@apollo/client";

import { GET_INIT_QUERY } from "graphql/query/user-gql";


const useInitQuery = ( fetchPolicy = "cache-only", withStatus = false ) => {

    const { data : { me, languages } = { }, loading, error } = useQuery( GET_INIT_QUERY, { fetchPolicy } );

    let languageDefault = {};

    if( me ){

        languages.map( ( item ) => {

            if( item.default === true ){
    
                languageDefault = item;
    
            }
    
        })

    }
    
    if( withStatus ) return { me, languages, languageDefault, loading, error };

    if( me ){

        return { me, languages, languageDefault };
    } 

    return false;
}

export default useInitQuery;