import React from "react";
import { useQuery } from "@apollo/client";

import { GET_GUEST_PARAMS } from "graphql/query/local-store-gql";

import { GuestRoutes } from "components/routes";

import "./guest-app.scss";


const GuestApp = () => {

    const { data : { guestParams } } = useQuery( GET_GUEST_PARAMS );

    return(
        <div className={ `guest-app ${ guestParams.appBlockClass.join(" ") }`}>
         
            <GuestRoutes />

        </div>
    );
};

export default GuestApp;