import React from "react";
import { Link } from "react-router-dom";

import LanguageConst from "../language-const";


const LanguageFieldTitleLink = ( { language } ) => {

    return (
        <Link
            className="table-link-underline"
            to={ `${ LanguageConst.basePath }/${ language.id }` }
        >
            { language.title }
        </Link>
    );

};

export default LanguageFieldTitleLink;