import React from 'react';
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button } from 'antd';

import { USER_DELETE } from 'graphql/mutation/user-gql';

import { errorNotification, successNotification } from "components/request-result";
import { EntityRemoveButton, Localize } from 'components/service';
import UserConst from '../user-const';


const basePath = UserConst.basePath;

const UserFormDelete = ( { user } ) => {

    let history  = useHistory();

    const [ userDelete, { loading } ] = useMutation( USER_DELETE,
        {
            update( cache, { data } ) {

                const {
                    userDelete: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title       : label,
                    description : message
                });

                history.push( `${ basePath }` );

            },
            onError( error ){
                errorNotification( error );
            }
        }
    );

    return (
        <>
            <EntityRemoveButton
                modalButton    = { 
                    <Button danger className = "delete" ><Localize>GL_Text_Delete</Localize></Button> 
                }
                nameEntity     = { Localize({ children: "USER_Title" }).props.children }
                dataNameEntity = { `${ user.name } ${ user.surname }` }
                loading        = { loading }
                deleteMutation = { userDelete }
                variables      = { {
                    id: user.id,
                } }
            />
        </>
    );

};

export default UserFormDelete;