import { gql } from '@apollo/client';


export const GET_LANGUAGE = gql`
    query Language( $id: ID ) {
        language( id: $id ) {
            id
            title
            slug
            status
            default
            flag
            created_at
            updated_at
        }
    }
`;

export const GET_LANGUAGES = gql`
    query getLanguages(
        $status: Boolean
    ){
        languages(
            status : $status
        ){
            id
            title
            slug
            status
            default
            flag
            created_at
            updated_at
        }
    }
`;
