import React from "react";
import { Typography, Popover } from 'antd';

import { WarningOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Localize } from "components/service";


const { Text } = Typography;

const infoText = (
    <Text>
        <Localize>GL_Text_InfoSync</Localize>
    </Text>
);

const FieldInfoTextSync = () => {

    return(

        <Popover className="ml-5" placement="right" content={ infoText } trigger="hover">
            {/* <FileSyncOutlined style={ { color: "#faad14" } } /> */}
            {/* <WarningOutlined style={ { color: "#2271b1" } } /> */}
            <InfoCircleOutlined style={ { color: "#1677ff" } } />
        </Popover>
    );

};
export default FieldInfoTextSync;
