import { useEffect, useState } from 'react';
import { Localize } from 'components/service'


const DateTime = () => {
    const [ date, setDate ] = useState(new Date());

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    }, []);

    const tick = () => {
        setDate(new Date());
    };



    return (
        <div className='date-time'>
            { date.toLocaleTimeString( Localize( { children: "GL_DATE_Time_Zone" } ).props.children, {
                day    : 'numeric',
                month  : 'short',
                year   : 'numeric',
                hour   : 'numeric',
                minute : 'numeric',
                second : 'numeric',
                hour12 : false,
            }) }
        </div>
    );
}

export default DateTime;