import React from "react";
import { Route, Switch } from 'react-router-dom';
import { useFilterStorage } from "components/use-hooks";

import UsersRoute      from "./user/users-route";
import LanguagesRoute  from "./language/languages-route";
// import GeneralRoute   from "./general/general-route";
import { Pages } from "components/pages";


const AppRoutes = () => {

    const filterStorage = useFilterStorage();

    filterStorage.restore( "all" );
    
    return (

        <Switch>
            <Route path="/" exact>
                <Pages.Dashboard />
            </Route>
            <Route path="/users">
                <UsersRoute />
            </Route>
            <Route path="/languages">
                <LanguagesRoute />
            </Route>
            {/* 
            <Route path="/general">
                <GeneralRoute />
            </Route>
             */}
            <Route path="*">
                <Pages.Page404 />
            </Route>
        </Switch>
    );
};

export default AppRoutes;