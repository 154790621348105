import React from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Checkbox } from 'antd';

import { useMutation } from "@apollo/client";
import { USER_CREATE } from "graphql/mutation/user-gql";

import { useVarParam }   from "components/use-hooks";
import { errorNotification, successNotification } from "components/request-result";
import { Localize }        from "components/service";
import UserConst           from "components/users/user-const";
import UserFormItems       from "../fields";
import LanguageSelectField from "components/language/fileds/language-select-field";


const basePath = UserConst.basePath;

const UserFormCreate = () => {

    let history = useHistory();

    const languageDefault = useVarParam().get( 'languageDefault' );

    const [form] = Form.useForm();
    const [userCreate, { loading }] = useMutation( USER_CREATE,
        {
            update( cache, { data } ) {

                const {
                    userCreate: {
                        label,
                        message,
                        user
                    }
                } = data;

                successNotification({
                    title       : label,
                    description : message
                });
                
                if( data?.userCreate?.user.id ){

                    history.push( `${ basePath }/${ user.id }` );
                   
                }

            },
            onError( error ) {
                errorNotification( error );
            }
        }
    );

    return (
        <>
            <Form
                key       = "create-user"
                layout    = "vertical"
                className = "user-form"
                form      = { form }
                onFinish  = { 
                    ( values ) => {
                        userCreate( {
                            variables : {
                                input : { 
                                    ...values 
                                }
                            }
                        } ).catch( ( error ) => {
                            errorNotification( error );
                        } );
                    }
                }
            >

                <Form.Item
                    name  = "name"
                    label = { <Localize>USER_Field_Name</Localize> }
                    rules = { [
                        { required: true, message: <Localize>GL_RULE_FieldRequired</Localize> }
                    ] }
                >
                    <Input name = "name"/>
                </Form.Item>

                <Form.Item
                    name  = "surname"
                    label = { <Localize>USER_Field_Surname</Localize> }
                    rules = { [
                        { message: <Localize>GL_RULE_FieldRequired</Localize> }
                    ] }
                >
                    <Input name="surname" />
                </Form.Item>

                <Form.Item
                    name         = "email"
                    label        = { <Localize>email</Localize> }
                    rules = { [
                        { required: true, message: <Localize>GL_RULE_FieldRequired</Localize> }
                    ] }
                >
                    <Input name="email" />
                </Form.Item>

                <Form.Item
                    name         = "phone"
                    label        = { <Localize>GL_Text_Phone</Localize> }
                    rules = { [
                        { message: <Localize>GL_RULE_FieldRequired</Localize> }
                    ] }
                >
                    <Input name="phone" />
                </Form.Item>

                <Form.Item
                    name         = "status"
                    label        = { <Localize>GL_Text_Status</Localize> }
                    initialValue = { 'active' }
                >
                    <UserFormItems.StatusSelect />
                </Form.Item>

                <Form.Item
                    name         = "lang"
                    label        = { <Localize>LANGUAGE_Title</Localize> }
                    initialValue = { languageDefault.slug }
                >
                    <LanguageSelectField />
                </Form.Item>

                <Form.Item
                    name          = "is_admin"
                    label         = { <Localize>Admin</Localize> }
                    valuePropName = "checked"
                >
                    <Checkbox><Localize>GL_Text_Yes</Localize>?</Checkbox>
                </Form.Item>

                <div className="form-btn-holder">

                    <Button
                        className = "btn-right"
                        loading   = { loading }
                        disabled  = { loading }
                        type      = "primary"
                        htmlType  = "submit"
                    >
                        <Localize>GL_Text_Create</Localize>
                    </Button>

                </div>

            </Form>
        </>
    );

};

export default UserFormCreate;