import { useQuery } from "@apollo/client";

import { GET_INIT_QUERY } from "graphql/query/user-gql";

import MockData from "components/mock-data";


const useTranslation = () => {

    const { translation } = MockData;

    const { loading, data } = useQuery( GET_INIT_QUERY );

    const defaultLang = "en";

    if ( !loading && data )
    {
        return { ...translation[ defaultLang ], ...translation[ data.me?.lang ] };
    }

    return { ...translation[ defaultLang ] }
}

export default useTranslation;