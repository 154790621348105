import React from "react";
import { Space, Tag, Divider, Timeline } from 'antd';

import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/layout"
import { Localize } from "components/service";
import { ClockCircleOutlined, SyncOutlined } from '@ant-design/icons';


const taskListWEB = [
    {
        children: 'web - MultiLanguage',
        dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
        color: 'orange',
    },
    {
        children: 'web - User Profile',
        dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
        color: 'orange',
    },
    {
        children: 'web - PersonalData CRUD',
        dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
        color: 'orange',
    },
    {
        children: 'web - PersonalData Content CRUD',
        color: 'blue',
    },
    {
        children: 'web - PersonalData Meta CRUD',
        color: 'grey',
    },
    {
        children: 'web - Relatives CRUD',
        color: 'blue',
    },
    {
        children: 'web - Agml CRUD',
        dot: <SyncOutlined spin style={{ fontSize: '16px' }} />,
        color: 'orange',
    },
    {
        children: 'web - PersonalData Media CRUD',
        color: 'grey',
    },
    {
        children: 'web - Auth forms',
        color: 'grey',
    },
    {
        children: 'web - FamilyTree',
        color: 'blue',
    },
    {
        children: 'web - проверить все мутации и квери на запрет действий',
        color: 'grey',
    },
];

const taskListADM = [
    {
        children: 'adm - MultiLanguage',
        dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
        color: 'orange',
    },
    // {
    //     children: 'adm - Hall CRUD',
    //     dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
    //     color: 'orange',
    // },
    // {
    //     children: 'adm - Event CRUD',
    //     dot: <SyncOutlined spin style={{ fontSize: '16px' }} />,
    //     color: 'blue',
    // },
    // {
    //     children: 'adm - EventDate CRUD',
    //     dot: <SyncOutlined spin style={{ fontSize: '16px' }} />,
    //     color: 'blue',
    // },
    {
        children: 'adm - Language CRUD',
        dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
        color: 'orange',
    },
    {
        children: 'adm - Users CRUD',
        color: 'grey',
    },
    {
        children: 'adm - PersonalData CRUD',
        color: 'grey',
    },
    {
        children: 'adm - PersonalData Meta CRUD',
        color: 'grey',
    },
    {
        children: 'adm - Relatives CRUD',
        color: 'grey',
    },
    {
        children: 'adm - Media CRUD',
        color: 'grey',
    },
    {
        children: 'adm - FamilyTree',
        color: 'grey',
    },

    
];

let countTasks = taskListADM.length + taskListWEB.length;

const Dashboard = () => {
    
    useActiveMenuItem([ "dashboard" ], [ "dashboard" ]);
    // return(
    //     <Card className="ml-15" style={{ width: 500 }} >
    //         <Timeline items = { taskList } />

    //         {/* <ol>
    //             <li>adm - Hall CRUD</li>
    //             <li>adm - Event CRUD</li>
    //             <li>adm - EventDate CRUD</li>
    //             <li>app - Show List EventDate for Event</li>
    //             <li>adm - Schema CRUD</li>
    //             <li>adm - Tickets CRUD & Add Tickets in schema</li>
    //             <li>adm - Language CRUD</li>
    //             <li>adm - Users CRUD</li>
    //             <li>app - Cart & Checkout</li>
    //             <li>adm - Order CRUD</li>
    //             <li>adm - OrderTickets CRUD</li>
    //             <li>adm - QrCode for Ticket Order</li>
    //             <li>app - SMS & Email Notification after Order</li>
    //             <li>adm - Event meta content</li>
    //             <li>adm - Event Taxonomy CRUD</li>
    //             <li>adm - Organizers CRUD</li>
    //             <li>adm - Partner CRUD</li>
    //             <li>adm - Agents CRUD</li>
    //             <li>adm - Page CRUD</li>
    //             <li>adm - Seo: Page & Event</li>
    //             <li>adm - PromoCod CRUD</li>
    //             <li>app - MyAccount Page</li>
    //             <li>app - Organizers Page</li>
    //             <li>app - Partners Page</li>
    //             <li>adm/app - Payments method</li>
    //             <li>adm/app - Scan Ticket</li>
    //             <li>adm/app - Other analytics</li>
    //         </ol> */}
    //     </Card>
        

    // );

    return (
        <PageWrapDefault
            dataExist={ true }
            className="page-dashboard"
            title={ <Localize>Dashboard</Localize> }
        >
            <div
                // style={ { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' } }
            >
                {/* <img src={ dashboardImg } style={ { width: '62%' } } alt="dashboard" /> */}

                <Divider orientation="center">Total</Divider>
                
                <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                    <Tag>{ countTasks } task</Tag>
                    <Tag color="green">0 - done</Tag>
                    <Tag color="processing">0 - processing</Tag>
                    <Tag color="orange">3 - pause</Tag>
                    <Tag color="grey">11 - waiting</Tag>
                </div>

                <Divider orientation="center">Details</Divider>

                <div style={ { display: 'flex', justifyContent: 'center' } } >
                    <Timeline key="1" items = { taskListADM } />
                    <Timeline key="2" className="ml-15" items = { taskListWEB } />
                </div>

            </div>

           
        </PageWrapDefault>
    );
}

export default Dashboard;