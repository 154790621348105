import ArrowIcon from "./arrow-icon";
import UsersIcon from "./users-icon";
import EventsIcon from "./events-icon";
import LastMinIcon from "./last-min-icon";
import LogOutIcon from "./log-out-icon";
import ProfileIcon from "./profile-icon";
import SubContractorsIcon from "./subcontractors-icon";
import EditIcon from "./edit-icon";
import LogsIcon from "./logs-icon";
import SendPassIcon from "./send-pass-icon";
import DeleteIcon from "./delete-icon";
import ChangePassIcon from "./change-pass-icon";
import FilterIcon from "./filter-icon";
import DownloadIcon from "./download-icon";
import ImportIcon from "./import-icon";
import UpdateIcon from "./update-icon";
import KeyIcon from "./key-icon";
import CraftsIcon from "./crafts-icon";
import AttentionIcon from "./attention-icon";
import GearIcon from "./gear-icon";
import InProcesIcon from "./in-proces-icon";
import NoJobsIcon from "./no-jobs-icon";
import PadlockIcon from "./padlock-icon";
import DocumentIcon from "./document-icon";
import {
    ApartmentOutlined,
    BankOutlined, 
    SettingOutlined, 
    TranslationOutlined, 
    SnippetsOutlined,
    WindowsOutlined,
    AppstoreAddOutlined,
    EnvironmentOutlined,
    WalletOutlined,
    CalendarOutlined,
    FileProtectOutlined
} from '@ant-design/icons';


const Icons = {
    Arrow         : ArrowIcon,
    Users         : UsersIcon,
    Language      : TranslationOutlined,
    PersonalData  : FileProtectOutlined,
    ThemeSettings : SettingOutlined,
    General       : AppstoreAddOutlined,
    LogOut: LogOutIcon,
    Profile: ProfileIcon,
    Edit: EditIcon,
    Logs: LogsIcon,
    SendPass: SendPassIcon,
    Delete: DeleteIcon,
    ChangePass: ChangePassIcon,
    Filter: FilterIcon,
    Download: DownloadIcon,
    Import: ImportIcon,
    Update: UpdateIcon,
    Key: KeyIcon,
    Attention: AttentionIcon,
    Gear: GearIcon,
    InProces: InProcesIcon,
    Padlock: PadlockIcon,
    Document: DocumentIcon
}

export default Icons;