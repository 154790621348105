import { gql } from '@apollo/client';

export const GET_INIT_QUERY = gql`
    query GetInitQuery {
        me{
            id
            name
            surname
            email
            phone
            lang
            status
            is_admin
            pdata_id
            is_online
            last_visit_ago
            last_visit_at
            created_at
            updated_at
        }
        languages(
            status : true
        ){
            id
            title
            slug
            status
            default
            flag
            created_at
            updated_at
        }
    }
`;

export const GET_ME = gql`
    query GetMe {
        me{
            id
            name
            surname
            email
            phone
            lang
            status
            is_admin
            pdata_id
            is_online
            last_visit_ago
            last_visit_at
            created_at
            updated_at
        }
        languages(
            status: true
        ){
            id
            title
            slug
            status
            default
            flag
            created_at
            updated_at
        }
    }
`;

export const GET_USERS = gql`
    query GetUsers(
        $text    : String
        $first   : Int
        $page    : Int
        $orderBy : [QueryUsersOrderByOrderByClause!]
        $where   : QueryUsersWhereWhereConditions
    ){
        users(
            text    : $text,
            first   : $first,
            page    : $page,
            orderBy : $orderBy,
            where   : $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                id
                name
                surname
                email
                phone
                lang
                status
                is_admin
                pdata_id
                is_online
                last_visit_ago
                last_visit_at
                created_at
                updated_at
              }
        }
    }
`;

export const GET_USER = gql`
    query GetUser(
        $id : ID
    ){
        user(
            id : $id
        ){
            id
            name
            surname
            email
            phone
            lang
            status
            is_admin
            pdata_id
            is_online
            last_visit_ago
            last_visit_at
            created_at
            updated_at
        }
    }
`;