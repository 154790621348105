import React from "react";
import { Typography } from 'antd';

const { Text } = Typography;

const FieldIdGroupId = ( { model } ) => {

    return(
        <div>
            <Text code type="">id: { model.id }</Text>
        </div>
    );

};

export default FieldIdGroupId;