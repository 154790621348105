import useVarParam  from "./use-var-param";
import useInitQuery from "./use-init-query";


const getLocalstorage = (storeName) => {
    return JSON.parse(localStorage.getItem(storeName));
}

const setLocalstorage = (storeName, storeData) => {
    localStorage.setItem(storeName, JSON.stringify(storeData));
}

const removeLocalstorage = (storeName) => {
    localStorage.removeItem(storeName);
}

const getStoreName = (storeName, userId) => {
    return `filter_${ storeName }_${userId}`;
}

const getAllFilterStores = () => {

    return Object.keys(localStorage)
                 .filter( item => item.indexOf("filter") !== -1 )
}

const useFilterStorage = () => {

    const appParams = useVarParam(),
        {
            me : {
                id : userId
            }
        } = useInitQuery(),
        moment = appParams.get("moment"),
        mainStoreName = "rememberQueryParams",
        allStoresNames = Object.keys( appParams.get( mainStoreName ) );

    const storeFilter = ( storeName, storeData ) => {

        const fullStoreName = getStoreName( storeName, userId );

        setLocalstorage(
            fullStoreName,
            {
                ...storeData,
                stored_at: moment().format("YYYY-MM-DD")
            }
        );

        appParams.set({
            [ mainStoreName ] : {
                ...appParams.get(mainStoreName),
                [ fullStoreName ] : storeData
            }
        })

    }

    const restoreFilter = (storeName) => {

        const storeNames = storeName === "all" ? allStoresNames : [ storeName ];

        let stores = {};

        storeNames.forEach( ( storeName ) =>  {

            const storeData = getLocalstorage(
                getStoreName(storeName, userId)
            )

            if(storeData){
                stores[ storeName ] = storeData;
            } else {
                removeFilter(storeName);
            }

        })

        appParams.set({
            [ mainStoreName ]: {
                ...appParams.get(mainStoreName),
                ...stores,
            }
        });

        if( moment().diff( getLocalstorage( "garbage_clean_at" ), "days", true ) >= 1 || !getLocalstorage( "garbage_clean_at" ) ){
            garbageCleaner();
        }
    }

    const garbageCleaner = () => {
        const allFilterStores = getAllFilterStores();

        allFilterStores.forEach( storeName => {
            const store = getLocalstorage(storeName);

            if(!store.stored_at || moment().diff(store.stored_at, "days", true) > 30){
                removeLocalstorage(storeName);
            }

        });

        setLocalstorage("garbage_clean_at", moment().format("YYYY-MM-DD"));
    }

    const removeFilter = (storeName) => {

        const storeNames = storeName === "all" ? allStoresNames : [ storeName ];

        storeNames.forEach( ( storeName ) =>  {
            appParams.set({

                [ mainStoreName ]: {
                    ...appParams.get(mainStoreName),
                    [ storeName ] : false
                }
            })

            removeLocalstorage( getStoreName(storeName, userId) );
        });

    }

    return {
        store   : storeFilter,
        restore : restoreFilter,
        remove  : removeFilter
    }

}


export default useFilterStorage;