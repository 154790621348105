const russia = {
    'GL_DATE_Time_Zone'         : 'ru-RU',
    'GL_Text_Date'              : 'Дата',
    'GL_Text_SelectDate'        : 'Выберите дату',
    'GL_PAGE_Text_404'          : 'Извините, страница, которую вы посетили, не существует',
    'GL_Text_NoDat'             : 'Нет данных',
    'GL_Text_Create'            : 'Создать',
    'GL_Text_AddNew'            : 'Добавить новый',
    'GL_Text_Title'             : 'Заголовок',
    'GL_Text_Slug'              : 'Slug',
    'GL_Text_Status'            : 'Статус',
    'GL_Text_Default'           : 'По умолчанию',
    'GL_Text_Action'            : 'Действие',
    'GL_Text_Delete'            : 'Удалить',
    'GL_Text_Edit'              : 'Редактировать',
    'GL_Text_SaveChanges'       : 'Сохранить',
    'GL_Text_Overview'          : 'Обзор',
    'GL_Text_View'              : 'просмотр',
    'GL_Text_Translations'      : 'Переводы',
    'GL_Text_Translation'       : 'Перевод',
    'GL_Text_MyProfile'         : 'Мой профиль',
    'GL_Text_Logout'            : 'Выход',
    'GL_Text_Filters'           : 'Фильтры',
    'GL_Text_Apply'             : 'Применить',
    'GL_Text_Reset'             : 'Сбросить',
    'GL_Text_ResetFilter'       : 'Сбросить фильтр',
    'GL_Text_Cancel'            : 'Отмена',
    'GL_Text_Confirm'           : 'GL_Text_Confirm',
    'GL_Text_InfoSync'          : 'Это поле автоматически синхронизируется с соответствующими объектами перевода',
    'GL_Text_Type'              : 'Тип',
    'GL_Text_Address'           : 'Адрес',
    'GL_Text_TranslateFor'      : 'перевод с',
    'GL_Text_Preview'           : 'предпросмотр',
    'GL_Text_Notes'             : 'Заметки',
    'GL_Text_StartSales'        : 'Старт продаж',
    'GL_Text_Total'             : 'всего',
    'GL_Text_Login'             : 'Login',
    'GL_Text_SingIn'            : 'Sing in',
    'GL_Text_Password'          : 'Password',
    'GL_Text_Yes'               : 'Да',
    'GL_Text_Phone'             : 'Телефон',
    'GL_Text_Active'            : 'Активный',
    'GL_Text_Block'             : 'Заблокирован',
    'GL_RULE_FieldRequired'     : 'Пожалуйста, заполните поле',
    'GL_Modal_Text_DeleteText'  : 'Вы уверены, что хотите удалить { deleteObjectType }?',
    'LANGUAGE_Titles'           : 'Языки',
    'LANGUAGE_Title'            : 'Язык',
    'LANGUAGE_Text_Create'      : 'Создать язык',
    'USER_Titles'               : 'Пользователи',
    'USER_Title'                : 'Пользователь',
    'USER_Text_Create'          : 'Создать пользователя',
    'USER_Field_Name'           : 'Имя',
    'USER_Field_Surname'        : 'Фамилия',
    'USER_Text_FullName'        : 'Полное имя',
    'USER_Text_LastVisit'       : 'Последнее посещение',
    'PERSONALDATA_Title'        : 'Личные данные',
    'GENERAL_Title'             : 'Общее',
};

export default russia;