import React from "react";


const FieldNoTranslate = () => {

    return(
        <small className="field-empty">No translate</small>
    );

};

export default FieldNoTranslate;
