import React, { useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";

import { GET_USERS } from "graphql/query/user-gql";

import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import User         from "components/users";


const basePath    = User.Const.basePath;
const tableHelper = User.Helpers.TableMain;

const UsersPage = () => {

    const breadcrumbs = [
        {
            label : Localize({ children: "USER_Titles" }).props.children,
            path  : `${ basePath }`
        }
    ];

    useBreadCrumbs( breadcrumbs );
    useActiveMenuItem( ["users"], ["users"] );

    let match = useRouteMatch( `${ basePath }/page/:pageNum` );

    const [ searchText, setSearchText ] = useState( "" );
    const [ filters, setFilters ]       = useState( {} );

    return (
        <PageWrapDefault
            className = "page-schemas page-table"
            dataExist = { true }
            title     = { <Localize>USER_Titles</Localize> }
        >
            <div className="table-action-bar">

                <div className="col">

                    {/* <Schema.Filters.Main 
                        setSearchText = { setSearchText } 
                        setFilters    = { setFilters } 
                        filters       = { filters }
                    /> */}

                </div>

                <div className="col">

                    <Link
                        className = "ant-btn ant-btn-primary"
                        to        = { `${ basePath }/create` }
                    >
                        <Localize>GL_Text_AddNew</Localize>
                    </Link>

                </div>

            </div>

            <Tables.Main
                modelName    = "users"
                query        = { GET_USERS }
                varSet       = { { perPage: 10 } }
                routeUrl     = { `${ basePath }` }
                currentPage  = { match ? parseInt( match.params.pageNum ) : 1 }
                searchText   = { searchText }
                objFilter    = { filters }
                tableHelper  = { tableHelper }
                totalCounter = { true }
            />
            
        </PageWrapDefault>
    )

};

export default UsersPage;