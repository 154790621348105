import React from "react";

import { useBreadCrumbs } from "components/use-hooks";
import { Localize }       from "components/service";
import { Fields }         from "components/layout";
import User               from "components/users";


const basePath = User.Const.basePath;

const UserPageSubOverView = ({ user }) => {

    const breadcrumbs = [
        {
            label : Localize( { children: "USER_Titles" } ).props.children,
            path  : `${ basePath }`
        },
        {
            label : `${ user.name } ${ user.surname }`,
            path  : `${ basePath }/${ user.id }`
        }
    ]

    useBreadCrumbs( breadcrumbs );

    return (
        <>
            <div className="d-flex justify-content-between mb-20 pb-15 divider-end">

                <Fields.IdGroupId model={ user } />

            </div>

            <div className="row-grid col-lg-2">

                <div className="col-left">

                    <User.Forms.Edit user={ user } />

                    <Fields.CreateUpdate model={ user } />

                </div>

            </div>
        </>
    );
};

export default UserPageSubOverView;