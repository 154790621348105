const english = {
    'GL_DATE_Time_Zone'         : 'en-GB',
    'GL_Text_Date'              : 'Date',
    'GL_Text_SelectDate'        : 'Select a date',
    'GL_PAGE_Text_404'          : 'Sorry, the page you visited does not exist.',
    'GL_Text_NoDat'             : 'No Data',
    'GL_Text_Create'            : 'Create',
    'GL_Text_AddNew'            : 'Add new',
    'GL_Text_Title'             : 'Title',
    'GL_Text_Slug'              : 'Slug',
    'GL_Text_Status'            : 'Status',
    'GL_Text_Default'           : 'Default',
    'GL_Text_Action'            : 'Action',
    'GL_Text_Delete'            : 'Delete',
    'GL_Text_Edit'              : 'Edit',
    'GL_Text_SaveChanges'       : 'Save changes',
    'GL_Text_Overview'          : 'overview',
    'GL_Text_View'              : 'view',
    'GL_Text_Translations'      : 'Translations',
    'GL_Text_Translation'       : 'Translation',
    'GL_Text_MyProfile'         : 'My profile',
    'GL_Text_Logout'            : 'Logout',
    'GL_Text_Filters'           : 'Filters',
    'GL_Text_Apply'             : 'Apply',
    'GL_Text_Reset'             : 'Reset',
    'GL_Text_ResetFilter'       : 'Reset filter',
    'GL_Text_Cancel'            : 'Cancel',
    'GL_Text_Confirm'           : 'GL_Text_Confirm',
    'GL_Text_InfoSync'          : 'This field is automatically synchronized with related translation objects',
    'GL_Text_Type'              : 'Type',
    'GL_Text_Address'           : 'Address',
    'GL_Text_TranslateFor'      : 'translate from',
    'GL_Text_Preview'           : 'preview',
    'GL_Text_Notes'             : 'Notes',
    'GL_Text_StartSales'        : 'Start of sales',
    'GL_Text_Total'             : 'total',
    'GL_Text_Login'             : 'Login',
    'GL_Text_SingIn'            : 'Sing in',
    'GL_Text_Password'          : 'Password',
    'GL_Text_Yes'               : 'Yes',
    'GL_Text_Phone'             : 'Phone',
    'GL_Text_Active'            : 'Active',
    'GL_Text_Block'             : 'Block',
    'GL_RULE_FieldRequired'     : 'Please input field',
    'GL_Modal_Text_DeleteText'  : 'Are you sure you want to delete the { deleteObjectType }?',
    'LANGUAGE_Titles'           : 'Languages',
    'LANGUAGE_Title'            : 'Language',
    'LANGUAGE_Text_Create'      : 'Create Language',
    'USER_Titles'               : 'Users',
    'USER_Title'                : 'User',
    'USER_Text_Create'          : 'Create user',
    'USER_Field_Name'           : 'Name',
    'USER_Field_Surname'        : 'Surname',
    'USER_Text_FullName'        : 'Full name',
    'USER_Text_LastVisit'       : 'Last visit',
    'PERSONALDATA_Title'        : 'Personal data',
    'GENERAL_Title'             : 'General',
};

export default english;