import React from "react";
import { useHistory } from 'react-router-dom';
import { Dropdown }   from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import { useMutation } from "@apollo/client";
import { GET_USERS }    from "graphql/query/user-gql";
import { USER_DELETE  } from "graphql/mutation/user-gql";

import UserConst from "../user-const";
import { errorNotification, successNotification } from "components/request-result";
import { EntityRemoveButton, Localize } from 'components/service';
import Icons from 'components/icons';


const basePath = UserConst.basePath;

const UserFieldActionList = ( { user } ) => {

    const history = useHistory();

    const [userDelete, { loading }] = useMutation( USER_DELETE,
        {
            refetchQueries: [GET_USERS],
            update( cache, { data } ) {

                const {
                    userDelete: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title       : label.toUpperCase(),
                    description : message
                });

            },
            onError( error) {
                errorNotification( error );
            }
        }
    );

    const items = [
        {
            label   : <Localize>GL_Text_Edit</Localize>,
            key     : '1',
            icon    : <Icons.Edit />,
            onClick : () => history.push(`${ basePath }/${ user.id }`)
        },
        {
            key  : '2',
            icon : <EntityRemoveButton
                modalButton    = {
                    <span 
                        style = { { display: 'flex', alignItems: 'center' } } 
                    >
                        <Icons.Delete /> <Localize>GL_Text_Delete</Localize>
                    </span>
                }
                nameEntity     = { Localize({ children: "USER_Title" }).props.children }
                dataNameEntity = { `${ user.name } ${ user.surname }` }
                loading        = { loading }
                deleteMutation = { userDelete }
                variables      = { { id: user.id } }
            />,
        }
    ];

    return (
        <>
            <Dropdown
                menu             = { { items } }
                overlayClassName = "drop-more-menu"
                placement        = "bottomRight"
                arrow
            >
                <EllipsisOutlined className="btn-more" />
            </Dropdown>
        </>
    );

};

export default UserFieldActionList;